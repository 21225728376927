@import "src/scss/utils/variables";

@font-face {
  font-family: "FuturaPTBook";
  src: url("./fonts/FuturaPTBook.eot");
  src: url("./fonts/FuturaPTBook.eot?#iefix") format("embedded-opentype"),
    url("./fonts/FuturaPTBook.woff2") format("woff2"),
    url("./fonts/FuturaPTBook.woff") format("woff"),
    url("./fonts/FuturaPTBook.ttf") format("truetype"),
    url("./fonts/FuturaPTBook.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "FuturaPTDemi";
  src: url("./fonts/FuturaPTDemi.eot");
  src: url("./fonts/FuturaPTDemi.eot?#iefix") format("embedded-opentype"),
    url("./fonts/FuturaPTDemi.woff2") format("woff2"),
    url("./fonts/FuturaPTDemi.woff") format("woff"),
    url("./fonts/FuturaPTDemi.ttf") format("truetype"),
    url("./fonts/FuturaPTDemi.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "FuturaPTMedium";
  src: url("./fonts/FuturaPTMedium.eot");
  src: url("./fonts/FuturaPTMedium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/FuturaPTMedium.woff2") format("woff2"),
    url("./fonts/FuturaPTMedium.woff") format("woff"),
    url("./fonts/FuturaPTMedium.ttf") format("truetype"),
    url("./fonts/FuturaPTMedium.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "FuturaPTBold";
  src: url("./fonts/FuturaPTBold.eot");
  src: url("./fonts/FuturaPTBold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/FuturaPTBold.woff2") format("woff2"),
    url("./fonts/FuturaPTBold.woff") format("woff"),
    url("./fonts/FuturaPTBold.ttf") format("truetype"),
    url("./fonts/FuturaPTBold.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "SourceHanSerifKHeavy";
  src: url("./fonts/SourceHanSerifK-Heavy.eot");
  src: url("./fonts/SourceHanSerifK-Heavy.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/SourceHanSerifK-Heavy.woff2") format("woff2"),
    url("./fonts/SourceHanSerifK-Heavy.woff") format("woff"),
    url("./fonts/SourceHanSerifK-Heavy.ttf") format("truetype"),
    url("./fonts/SourceHanSerifK-Heavy.svg#svgFontName") format("svg");
}

$font-book: "FuturaPTBook";
$font-demi: "FuturaPTDemi";
$font-medium: "FuturaPTMedium";
$font-bold: "FuturaPTBold";
$font-heading: $font-oldstyle;

$color-white: #ffffff;
$color-light: #faf7f5;
$color-dark: #37404a;
$color-dark2: #7b8b9d;
$color-dark3: #2c323a;
$color-green: #72bfb9;
$color-orange: #ffaa39;
$color-orange-light: #fcf2e0;
$color-yellow-light: #fcf8e0;
$color-yellow-light2: #f8e29a;
$color-yellow-light3: #fcf6e0;
$color-green-light: #ecf7f5;
$color-green-light2: #d0eae7;

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-20px);
  }
}
