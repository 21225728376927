@import "~breakpoint-sass/stylesheets/_breakpoint";
@import "src/scss/utils/variables";
@import "src/scss/utils/mixins";

.behavioral-dimension {
    background: url(../../assets/img/product/product-bg-1.svg) no-repeat center;
    background-size: 102%;
    min-height: 550px;
    position: relative;
    margin-bottom: 100px;
    @extend %with-large-background;

    @include breakpoint( 0px $screen-md) {
      background: none;
    }

    .section-text {
        position: relative;

        h3 {
            margin-bottom: 5px;
        }

        h4 {
            font-family: $font-futura-demi;
            font-size: 18px;
            text-transform: uppercase;
            line-height: 25px;
            letter-spacing: 1px;
        }
        p {
            font-size: 25px;
            max-width: 600px;
            line-height: 40px;
            @include breakpoint($screen-xs $screen-md) {
                margin: 0 auto;
                font-size: 20px;
                line-height: 30px;
            }
        }
    }

    .behavioral-dimension-img {
        width: 100%;
    }
    @extend %responsive-content-section;
}

.cognitive-dimension {
    background: url(../../assets/img/product/product-bg-2.svg) no-repeat center;
    background-size: 102%;
    background-position-y: 0;
    min-height: 390px;
    position: relative;
    @extend %with-large-background;
    margin-bottom: 100px;
    @extend %with-small-no-background;

    .section-text {
        margin-right: auto;
        position: relative;

        @include breakpoint($screen-xs $screen-md) {
          position: relative;
          top: 20px;
        }

        h3 {
            margin-bottom: 5px;
        }
        h4 {
            font-size: 18px;
            text-transform: uppercase;
            line-height: 25px;
            letter-spacing: 1px;
            font-family: $font-futura-demi;
        }
        p {
            font-size: 25px;
            max-width: 535px;
            line-height: 40px;
            @include breakpoint($screen-xs $screen-md) {
                margin: 0 auto;
                font-size: 20px;
                line-height: 30px;
            }
        }
    }

    .section-image {
        width: 100%;

        @include breakpoint($screen-xs $screen-md) {
            margin-right: -15px;
        }
        @extend %with-large-images;
    }

    @extend %mobile-reverse;
    @extend %responsive-content-section;
}

.emotional-dimension {
    background: url(../../assets/img/product/product-bg-3.svg) no-repeat top;
    background-size: 102%;
    min-height: 490px;
    position: relative;
    margin-bottom: 100px;
    @extend %with-large-background;
    @extend %with-small-no-background;

    .section-text {
        h3 {
            margin-bottom: 5px;
        }
        h4 {
            font-size: 18px;
            text-transform: uppercase;
            line-height: 25px;
            letter-spacing: 1px;
            font-family: $font-futura-demi;
        }
        p {
            font-size: 25px;
            max-width: 600px;
            line-height: 40px;
            @include breakpoint($screen-xs $screen-md) {
                margin: 0 auto;
                font-size: 20px;
                line-height: 30px;
            }
        }
    }

    .emotional-dimension-img {
        width: 100%;
    }
    @extend %responsive-content-section;
}

.journey-container {
  .journey-img {
    width: 100%;
  }
}

.link-section {
    margin-bottom: 80px;
    @include breakpoint($screen-xs $screen-md) {
        margin-bottom: 50px;
    }
}

.outcomes-evidence {
    padding: 70px 0 65px;
    background-color: $spring-wood;

    h3 {
        line-height: 50px;
        margin-top: 0;
    }

    .left-side {
        h3 {
            font-size: 30px;
            margin-bottom: 50px;
            @include breakpoint($screen-md) {
                font-size: 35px;
                margin-bottom: 0;
            }
        }
    }

    .right-side {
        padding: 0 30px;
        @include breakpoint($screen-md) {
            padding: 0 15px;
        }
    }
    p {
        font-family: $font-futura;
        font-size: 20px;
        color: $limed-spruce;
        letter-spacing: 0;
        line-height: 30px;
        @include breakpoint($screen-md) {
            line-height: 40px;
            font-size: 25px;
        }

    }

    @extend %responsive-outcomes;
}

.reduced-text, .improved-text {
    font-family: $font-futura-demi;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 25px;
    text-transform: uppercase;
}

.reduced-text {
    color: $alizarin-crimson;
}

.improved-text {
    color: $pistachio;
}

.sources {
  li {
    margin: 10px 0;
  }
}

.journey-desktop {
    display: none;
    @include breakpoint($screen-sm) {
        display: block;
    }
}
.journey-mobile {
    display: block;
    @include breakpoint($screen-sm) {
        display: none;
    }
    .journey-item {
        margin-bottom: 50px;
        &:last-child {
            margin-bottom: 0;
        }
        h3 {
            margin-top: 10px;
        }
        .item-text {
            font-family: $font-futura-book;
            font-size: 20px;
            color: $limed-spruce;
            letter-spacing: 0;
            text-align: center;
            line-height: 30px;
            padding: 0 15px;
        }
    }
}