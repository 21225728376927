@import "~breakpoint-sass/stylesheets/_breakpoint";
@import "src/scss/utils/variables";
@import "src/scss/utils/mixins";

.contact-bg {
  background: url(../../assets/img/contact/contact-bg.png) no-repeat bottom;
  margin-top: 0;
  @include breakpoint($screen-md) {


    margin-top: 65px;
    background-position-x: right;
    background-size: 100% 100%;
  }
}

.contact-us {
  padding: 5px 0 10px;
  @include breakpoint($screen-md) {
    padding: 45px 0 50px;
  }
  @include breakpoint($screen-lg) {
    padding: 90px 0 100px;
  }
}