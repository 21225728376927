@import "../../styles/variables";

.section_1_bg {
  background-image: url(../../images/line4.png);
  background-repeat: no-repeat;
  background-position: center bottom 25px;
  padding-bottom: 0px;
}
.section_2_bg {
  background-image: url(../../images/line5.png);
  background-repeat: no-repeat;
  background-position: center bottom 75px;
  padding-bottom: 50px;
}
.section_3_bg {
  background-image: url(../../images/line4.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 0px;
}
.section_4_bg {
  background-image: url(../../images/line6.png);
  background-repeat: no-repeat;
  background-position: center bottom 183px;
  padding-bottom: 60px;
}

.section {
  padding: 75px 0;
  overflow: hidden;
  @media (max-width: 767px) {
    padding: 30px 0;
  }
  &.section_program {
    padding-bottom: 0;
  }
}
.heading_box {
  max-width: 830px;
  margin: 0 auto;
  margin-bottom: 60px;
  text-align: center;
  @media (min-width: 1500px) {
    margin-bottom: 100px;
  }
  @media (min-width: 1900px) {
    max-width: 1000px;
  }
}
.program_full {
  position: relative;
  padding-bottom: 140px;
  .line_design1 {
    position: absolute;
    top: 220px;
    left: 0;
    width: 100%;
    background-color: $color-green-light;
    height: 40px;
    &:before {
      content: "";
      display: block;
      position: absolute;
      height: 40px;
      background-color: $color-light;
      left: -30px;
      width: calc(50% + 200px);
      top: 170px;
      border-radius: 20px;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 40px;
      background-color: $color-light;
      left: calc(50% + 110px);
      width: 200px;
      top: 110px;
      border-radius: 20px;
      transform: rotate(-45deg);
    }
  }
  .line_design2 {
    position: absolute;
    bottom: 80px;
    left: 0;
    width: calc(50% + 240px);
    background-color: $color-green-light;
    height: 40px;
    border-radius: 20px;
    &:before {
      content: "";
      position: absolute;
      background-color: $color-green-light;
      width: 150px;
      height: 40px;
      border-radius: 20px;
      transform: rotate(45deg);
      right: -98px;
      top: 40px;
    }
    &:after {
      content: "";
      position: absolute;
      background-color: $color-green-light;
      height: 40px;
      border-radius: 20px;
      left: calc(100% + 45px);
      right: -5000px;
      top: 80px;
    }
  }
}
.program_box {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  .program_item {
    max-width: 520px;
    @media (max-width: 767px) {
      padding-top: 40px;
      margin: 0 auto;
    }
  }
}
.section_content {
  background-color: $color-light;
  .about_text {
    max-width: 1170px;
    margin: 0 auto;
    font-size: 1.0625rem;
    color: $color-dark2;
  }
}
.exp_box {
}
.exp_item {
  position: relative;
  .line {
    position: absolute;
    left: calc(50% + 40px);
    top: 20px;
    width: 40px;
    bottom: 100px;
    background-color: $color-yellow-light3;
    border-radius: 20px;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: calc(100% - 50px);
      right: 51px;
      width: 40px;
      height: 240px;
      background-color: $color-yellow-light3;
      border-radius: 20px;
      transform: rotate(30deg);
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: -20px;
      width: 80px;
      height: 80px;
      background-color: $color-orange;
      border-radius: 50%;
    }
  }
  &:nth-child(even) {
    .line {
      left: calc(50% - 62px);
      top: 20px;
      &:before {
        transform: rotate(-30deg);
        right: -50px;
      }
    }
  }
  &:last-child {
    .line {
      bottom: 0;
      &:before {
        display: none;
      }
      &:after {
        top: 35%;
      }
    }
  }
  &:nth-child(2) {
    .line {
      &:after {
        background-color: $color-yellow-light2;
      }
    }
  }
  &:nth-child(3) {
    .line {
      &:after {
        background-color: $color-green;
      }
    }
  }
}
.data_item {
  + .data_item {
    margin-top: 100px;
    @media (max-width: 767px) {
      margin-top: 50px;
    }
  }
}
.note_text {
  max-width: 510px;
  font-style: italic;
  margin-top: 25px;
}
.content_box1 {
  width: 120%;
  .data_content {
    margin-top: 115px;
    @media (max-width: 767px) {
      margin-top: 30px;
    }
  }
  @media (max-width: 1299px) {
    width: 110%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
}

.section_program {
  background-color: $color-light;
  div[data-container] {
    > div[data-heading] {
      text-align: center;
    }
  }
}
.program_main {
  position: relative;
  padding: 50px 0;
  max-width: 1500px;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding-top: 0px;
  }
}
.program_center {
  width: 320px;
  height: 320px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    max-width: 100%;
    max-height: 100%;
  }
  @media (max-width: 1199px) {
    width: 250px;
    height: 250px;
  }
  @media (max-width: 767px) {
    display: none;
  }
}
.program_box {
  position: relative;
  min-height: 900px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: 768px) {
    .program_item {
      width: 33%;
      &:nth-child(1),
      &:nth-child(3) {
        margin-top: 150px;
      }
      &:nth-child(4) {
        margin-right: 100px;
      }
      &:nth-child(5) {
        margin-left: 100px;
      }
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    .program_item {
      position: relative;
      &:before {
        content: "";
        display: block;
        width: 18px;
        height: calc(100% + 25px);
        bottom: 15px;
        background-color: $color-green-light2;
        border-radius: 10px;
        position: absolute;
        left: 50%;
        margin-left: -9px;
        z-index: -1;
      }
      &:after {
        content: "";
        display: block;
        width: 54px;
        height: 54px;
        bottom: 10px;
        background-color: $color-green-light2;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        margin-left: -27px;
        z-index: -1;
      }
      &:nth-child(1) {
        order: 2;
      }
      &:nth-child(2) {
        order: 0;
        padding-top: 20px;
        &:before {
          display: none;
        }
      }
      &:nth-child(4) {
        order: 6;
      }
    }
  }
}
.section_green {
  background-color: $color-green-light;
}
.help_content {
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .help_left {
    flex: 1;
    div[data-heading] {
      max-width: 280px;
      @media (min-width: 1500px) {
        max-width: 400px;
      }
      @media (max-width: 767px) {
        max-width: none;
      }
    }
  }
  .help_right {
    flex: 1;
  }
}
.content_box2 {
  width: 113%;
  @media (max-width: 1199px) {
    width: 100%;
  }
}
.section_2 {
  padding-bottom: 150px;
  @media (max-width: 767px) {
    padding-bottom: 50px;
  }
}
.section_4 {
  background-color: $color-yellow-light;
  padding-bottom: 0;
  position: relative;
  .testi_img {
    @media (min-width: 768px) {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50%;
      max-width: 800px;
    }
    @media (max-width: 767px) {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}
.testi_box {
  display: flex;
  align-items: flex-end;
  .testi_left {
    flex: 1;
  }
  .testi_right {
    flex: 1;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    .testi_left {
      order: 3;
    }
    .testi_right {
      width: 100%;
      padding-left: 20px;
      padding-right: 10px;
      .testi_text {
        max-width: none;
      }
    }
  }
  @media (max-width: 479px) {
    .testi_right {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
.testi_img {
  img {
    display: block;
    max-width: 100%;
  }
}
.testi_text {
  margin-bottom: 80px;
  font-size: 1.5rem;
  max-width: 620px;
  margin-left: auto;
  position: relative;
  line-height: 1.75;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
  p {
    font-style: italic;
    margin-bottom: 30px;
    @media (max-width: 767px) {
      font-style: normal;
      text-align: center;
    }
  }
  .author {
    text-align: right;
    @media (max-width: 767px) {
      text-align: center;
    }
  }
  .quote1 {
    position: absolute;
    top: -40px;
    left: -40px;
    width: 180px;
    height: 140px;
    opacity: 0.5;
    img {
      width: 100%;
      height: 100%;
    }
    @media (max-width: 767px) {
      width: 100px;
      height: 80px;
      left: -20px;
      top: -25px;
    }
    @media (max-width: 479px) {
      left: -10px;
    }
  }
  .quote2 {
    position: absolute;
    bottom: -50px;
    right: -50px;
    width: 180px;
    height: 140px;
    transform: rotate(180deg);
    opacity: 0.5;
    img {
      width: 100%;
      height: 100%;
    }
    @media (max-width: 767px) {
      width: 100px;
      height: 80px;
      right: -10px;
    }
    @media (max-width: 479px) {
      bottom: -15px;
    }
  }
}
.text_data {
  position: relative;
}
