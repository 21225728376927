// This file contanins only helper mixins. Base components can be found in _base file.

@mixin centerer($axis: 'both') {
    position: absolute;
    @if ($axis == 'both') {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
    }
    @if ($axis == 'y') {
        top: 50%;
        transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
    }
    @if ($axis == 'x') {
        left: 50%;
        transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -o-transform: translateX(-50%);
    }
}

@mixin flex-parent() {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

@mixin border-radius($radius) {
    $borderRadius: $radius;
    -webkit-border-radius: $borderRadius;
    -moz-border-radius: $borderRadius;
    -o-border-radius: $borderRadius;
    border-radius: $borderRadius;
}

@mixin box-shadow($shadow) {
    $shadowValue: $shadow;
    -webkit-box-shadow: $shadowValue;
    -moz-box-shadow: $shadowValue;
    box-shadow: $shadowValue;
}

@mixin image-2x($image) {
    @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
        /* on retina, use image that's scaled by 2 */
        background-image: url($image);
    }
}


// String Replace for Font Face mixin

@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

// Font face

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;

    $extmods: (
            eot: "?",
            svg: "#" + str-replace($name, " ", "_")
    );

    $formats: (
            otf: "opentype",
            ttf: "truetype"
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}

@mixin backgroundImage($url, $size: 'cover' ) {
    background-image: url($url);
    background-size: $size;
}

%responsive-content-section {
    @include breakpoint($screen-xs $screen-md) {
        margin-bottom: 20px !important;
        padding-top: 20px !important;
        min-height: 450px !important;
    }

    img {
        @include breakpoint($screen-xs $screen-md) {
            margin: 20px auto 0;
        }

        &.mobile-left {
            @include breakpoint($screen-xs $screen-md) {
                margin: 20px 0;
            }
        }
        &.mobile-right {
            @include breakpoint($screen-xs $screen-md) {
                margin: 20px 0 0 auto;
            }
        }
    }

    div.section-text {
        @include breakpoint($screen-xs $screen-md) {
            text-align: center;
            margin: 0;
            font-size: 30px;
            line-height: 35px;
            width: 100%;
        }
    }

    &.mobile-reverse {
        @include breakpoint($screen-xs ($screen-md -1)) {
            -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
        }
    }
}

%responsive-outcomes {
    @include breakpoint($screen-xs $screen-md) {
        padding: 30px 0 0 !important;
        text-align: center;
    }
}

%mobile-reverse {
    .mobile-reverse {
        @include breakpoint($screen-xs $screen-sm) {
            margin: 35px 0;
            -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
        }
    }
}

%with-large-images {
    width: 100%;
    &:not(.large) {
        display: block;

        @include breakpoint($screen-xl) {
            display: none;
        }
    }
    &.large {
        display: none;
        @include breakpoint($screen-xl) {
            display: block;

        }
    }
}

%with-large-background {
    @include breakpoint($screen-xl) {
        background-size: 102% !important;
    }
}

%with-small-no-background {
  @include breakpoint( 0px $screen-md) {
    background: none !important;
  }
}