@import "~breakpoint-sass/stylesheets/_breakpoint";
@import "src/scss/utils/variables";
@import "src/scss/utils/mixins";

// Home Hero
.home-hero {

  .hero-home-text {
    padding-top: 1rem;
    padding-right: 30px;
    padding-left: 30px;

    @include breakpoint($screen-md) {
      padding-top: 3rem;
    }

    > div {

      @include breakpoint($screen-md) {
        position: absolute;
        top: 140px;
        left: 160px;
      }

      @include breakpoint($screen-lg) {
        position: absolute;
        top: 140px;
        left: 170px;
      }
      h1 {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 15px;
        @include breakpoint($screen-md) {
          margin-bottom: 15px;
          font-size: 50px;
          line-height: 50px;
        }
        @include breakpoint($screen-lg) {
          margin-bottom: 15px;
          font-size: 75px;
          line-height: 90px;
        }
      }

      .subheading {
        margin-bottom: 25px;
        @include breakpoint($screen-md) {
          margin-bottom: 50px;
        }
      }

      @include breakpoint($screen-xl) {
        left: 100px;
      }
    }
  }
  .hero-home-img {
    padding-top: 1rem;

    @include breakpoint($screen-xl) {
      padding-top: 3rem;
    }

    > img {
      width: 100%;
      height: auto;
      &:not(.large) {
        display: block;
        @include breakpoint($screen-xl) {
          display: none;
        }
      }
      &.large {
        display: none;
        @include breakpoint($screen-xl) {
          display: block;
        }
      }
    }
  }
}

.lh-1-5 {
 line-height: 1.5;
}

.p-left-179 {
  padding-left: 179px !important;
}

.title-padding {
  padding-top: 80px;
}

.bg-color-spring-wood {
  background: $spring-wood;

  .figure-avenue {
    margin: 0 -50px;
  }
}

.m-top-100 {
  margin-top: 100px;
}

.m-bottom-100 {
  margin-bottom: 100px;
}

.spring-wood-line-bg {
  @include breakpoint($screen-md) {
    background: url(../../assets/img/homepage/home-bg-1.svg) no-repeat;
    background-position-y: 360px;
    background-size: 102%;
    padding-bottom: 70px;
    background-position-x: -15px;

  }
  @include breakpoint($screen-lg) {
    background-position-y: 450px;
  }
  @include breakpoint($screen-xl) {
    background-position-y: 425px;
  }
}

div.oval-bg {

  @include breakpoint($screen-md) {
    background: url(../../assets/img/homepage/oval.png) no-repeat;
    background-position-y: 60px;
    background-position-x: 75px;
    background-size: 250px;
  }
  @include breakpoint($screen-lg) {
    background-size: 318px;
  }
  @include breakpoint($screen-xl) {
    background-position-x: 0;
  }
}

.green-line-bg {
  background: url(../../assets/img/homepage/home-bg-1.svg) no-repeat;
  background-size: cover;
  @extend %with-large-background;
}

.yellow-line-bg {
  background: url(../../assets/img/homepage/home-bg-2.svg) no-repeat;
  background-position-y: 75%;
  background-size: 102%;
  padding-bottom: 130px;
  background-position-x: -15px;
  @extend %with-large-background;

  .text {
    @include breakpoint( 0px $screen-md) {
      text-align: center;
    }
  }
}

.no-underline {
  text-decoration: none;
}

.icon-home {
  height: 100px;
  margin-bottom: 20px;
}

.icon-home-figure {
  height: 100px;
  margin-bottom: 20px;


  &.left-icon {
    position: relative;
    top: 10px;
    left: -25px;
    @include breakpoint($screen-lg) {
      left: -15px;
    }
  }
  &.right-icon {
    position: relative;
    top: 60px;
    right: -10px;
    @include breakpoint($screen-lg) {
      top: 80px;
      right: 0;
    }
  }
}

ul.list-home-page {
  margin: 0 0 1rem 0;
  font-size: 20px;
  font-family: $font-futura-demi;
  font-weight: 600;
  color: $limed-spruce;
  line-height: 30px;
  list-style: none;
  text-align: left;
  padding: 0;

  @include breakpoint($screen-md){
    font-size: 25px;
  }

  li {
    background: url(../../assets/img/homepage/checkmark-circle-25.svg) no-repeat left top; /* <-- change `left` & `top` too for extra control */
    padding: 0 0 0 40px;
    background-position-y: 2px;
    margin-bottom: 30px;

    img {
      margin-right: 0;
      @include breakpoint($screen-md) {
        margin-right: 10px;
      }
    }
    span {
      display: block;
      vertical-align: middle;

      @include breakpoint($screen-md) {
        display: inline-block;
      }
    }
  }
}

.p-10-percent {
  padding-right: 10%;
  padding-left: 10%;
}

.covid-alert {
  background-color: $alizarin-crimson;
  color: $white;
  height: 70px;

  @include breakpoint($screen-sm) {
    height: 50px;
    margin-bottom: 0;
  }
  img {
    display: none;
    @include breakpoint($screen-sm) {
      display: inline-block;
    }
  }

  .warning-icon {
    margin-right: 10px;
  }
}

@media (max-width: 1140px) {
  .yellow-line-bg {
    background: none;
    padding-bottom: 40px;
  }

  .mt-sm-5 {
    margin-top:3rem!important;
  }

  .doctor-img {
    height: 450px;
    float: right;
  }

  .laptop-home {
    height: 250px;
  }

  .icon-home-figure {
    height: 50px;
    margin-bottom: 20px;

    &.nurse-icon {
      position: relative;
      top: 40px;
    }
  }

  .green-line-mobile {
    background: url(../../assets/img/homepage/green-line-mobile.png) no-repeat bottom;
  }

  .bg-circle-mobile {
    background: url(../../assets/img/homepage/cricle-mobile.png) no-repeat center center;
    height: 55px;
    margin-bottom: 40px;
    line-height: 55px;

    &.first-child {
      margin-top: 40px;
    }

    &.last-child {
      margin-bottom: 0;
    }
  }

  .m-bottom-100 {
    margin-bottom: 50px;
  }
}

@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 1) {
  .doctor-img {
    height: 420px;
  }
}

@media (max-width: 634px) {


  .doctor-img {
    height: 300px;
    float: right;
  }
}

@media (min-width: 1140px) {
  .doctor-img {
    height: 659px;
  }

  .laptop-home {
    height: 500px;
  }
}

.outcomes-spring-wood {
  background: $spring-wood;
  padding: 95px 0 0;

  h3 {
    line-height: 50px;
  }
  p {
    font-family: $font-futura;
    line-height: 30px;
    font-size: 20px;

    @include breakpoint($screen-md) {
      line-height: 40px;
      font-size: 25px;
    }
  }

  .right-side {
    .row {
      margin-bottom: 50px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @extend %responsive-outcomes;
}

.body-copy-small img {
  margin: 0 10px;
}

.section-1-mobile {
  display: block;
  h2 {
    font-size: 25px;
    line-height: 35px;
  }
  @include breakpoint($screen-md) {
    display: none;
  }
}
.section-1-desktop {
  display: none;
  @include breakpoint($screen-md) {
    display: block;
  }
}

.hero-text {
  h3 {
    font-size: 25px;
    line-height: 35px;
  }
}

.our-capabilities {
  h2 {
    margin-bottom: 50px;

    @include breakpoint($screen-md) {
      margin-bottom: 0.5rem;
    }
  }

  h3 {
    margin-top: 20px;

    @include breakpoint($screen-md) {
      margin-top: 0;
    }

    @include breakpoint($screen-lg) {
      margin-top: 1rem;
    }
  }

  .checkmark-circle {
    margin-top: 20px;

    @include breakpoint($screen-md) {
      margin-top: 0;
    }

    @include breakpoint($screen-lg) {
      margin-top: 1.2rem;
    }
  }
}

.provider-payors-footer {
  @include breakpoint( 0px $screen-md) {
    text-align: center;
  }
}


