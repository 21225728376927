@import "../../styles/variables";

.item {
  max-width: 400px;
  text-align: center;
  margin: 0 auto;
  .icon {
    width: 180px;
    height: 180px;
    margin: 0 auto 30px;
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
    img {
      max-width: 100%;
    }
    @media (max-width: 1199px) {
      width: 120px;
      height: 120px;
    }
  }
  .info {
    @media (max-width: 767px) {
      min-height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      div[data-heading] {
        font-size: 1.5rem;
        font-family: $font-book;
        margin-bottom: 0;
        font-weight: normal;
      }
    }
  }
}
