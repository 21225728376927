@import "../../styles/variables";

.section_1_bg {
  background-image: url(../../images/line1.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 20px;
}
.section_2_bg {
  background-image: url(../../images/line3.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 80px;
}
.section_3_bg {
  background-image: url(../../images/line1.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 0px;
}
.section_4_bg {
  background-image: url(../../images/line2.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 60px;
}
.section_5_bg {
  background-image: url(../../images/line1.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 0px;
  [data-content] {
    margin-top: 100px;
  }
}
// .bg1 {
//   background-image: url(../../images/line1.png);
//   background-repeat: no-repeat;
//   background-position: center bottom 60px;
// }
// .bg2 {
//   background-image: url(../../images/line2.png);
//   background-repeat: no-repeat;
//   background-position: center bottom;
// }
// .bg3 {
//   background-image: url(../../images/line3.png);
//   background-repeat: no-repeat;
//   background-position: center bottom;
// }

.section {
  padding: 75px 0;
  overflow: hidden;

  @media (max-width: 767px) {
    padding: 30px 0;
  }
  &.section_program {
    padding-bottom: 0;
  }
  .section_2 {
    [data-card-design] {
      &:before {
        background-image: url(../../images/line3.png);
      }
    }
    div[data-img-box] {
      > div {
        @media (min-width: 768px) {
          right: 11% !important;
        }
        @media (max-width: 767px) {
          padding-right: 30px;
          max-width: 60% !important;
          margin-top: 30px;
        }
        @media (max-width: 479px) {
          max-width: 70% !important;
          margin-top: 10px;
        }
      }
    }
  }
}
.heading_box {
  max-width: 1100px;
  @media (min-width: 1900px) {
    max-width: 1100px;
  }
  margin: 0 auto;
  margin-bottom: 60px;
  text-align: center;
  @media (max-width: 767px) {
    margin-bottom: 50px;
  }
}
.program_full {
  position: relative;
  padding-bottom: 140px;
  .line_design1 {
    position: absolute;
    top: 220px;
    left: 0;
    width: 100%;
    background-color: $color-green-light;
    height: 40px;
    &:before {
      content: "";
      display: block;
      position: absolute;
      height: 40px;
      background-color: $color-light;
      left: -30px;
      width: calc(50% + 200px);
      top: 170px;
      border-radius: 20px;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 40px;
      background-color: $color-light;
      left: calc(50% + 110px);
      width: 200px;
      top: 110px;
      border-radius: 20px;
      transform: rotate(-45deg);
    }
  }
  .line_design2 {
    position: absolute;
    bottom: 80px;
    left: 0;
    width: calc(50% + 240px);
    background-color: $color-green-light;
    height: 40px;
    border-radius: 20px;
    &:before {
      content: "";
      position: absolute;
      background-color: $color-green-light;
      width: 150px;
      height: 40px;
      border-radius: 20px;
      transform: rotate(45deg);
      right: -98px;
      top: 40px;
    }
    &:after {
      content: "";
      position: absolute;
      background-color: $color-green-light;
      height: 40px;
      border-radius: 20px;
      left: calc(100% + 45px);
      right: -5000px;
      top: 80px;
    }
  }
}
.program_box {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  .program_item {
    max-width: 520px;
  }
}
.section_content {
  background-color: $color-light;
  .about_text {
    max-width: 1170px;
    margin: 0 auto;
    font-size: 1.0625rem;
    color: $color-dark2;
  }
}
.exp_box {
}
.exp_item {
  position: relative;
  .line {
    position: absolute;
    left: calc(50% + 40px);
    top: 20px;
    width: 40px;
    bottom: 100px;
    background-color: $color-yellow-light3;
    border-radius: 20px;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: calc(100% - 50px);
      right: 51px;
      width: 40px;
      height: 240px;
      background-color: $color-yellow-light3;
      border-radius: 20px;
      transform: rotate(30deg);
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: -20px;
      width: 80px;
      height: 80px;
      background-color: $color-orange;
      border-radius: 50%;
    }
  }
  &:nth-child(even) {
    .line {
      left: calc(50% - 62px);
      top: 20px;
      &:before {
        transform: rotate(-30deg);
        right: -50px;
      }
    }
  }
  &:last-child {
    .line {
      bottom: 0;
      &:before {
        display: none;
      }
      &:after {
        top: 35%;
      }
    }
  }
  &:nth-child(2) {
    .line {
      &:after {
        background-color: $color-yellow-light2;
      }
    }
  }
  &:nth-child(3) {
    .line {
      &:after {
        background-color: $color-green;
      }
    }
  }
}
.data_item {
  + .data_item {
    margin-top: 100px;
    @media (max-width: 767px) {
      margin-top: 50px;
    }
  }
  &.data_item2 {
  }
}
.note_text {
  max-width: 510px;
  font-style: italic;
  margin-top: 25px;
}
.section_3 {
  margin-bottom: 100px;
  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
}
