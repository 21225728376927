@import "~breakpoint-sass/stylesheets/_breakpoint";
@import "src/scss/utils/variables";
@import "src/scss/utils/mixins";

.payors-graphic-container {
    position: relative;

    .payors-graphic-img {
        width: 65%;
        @include breakpoint($screen-md) {
            width: 80%;
            margin-left: -30px;
        }

        @include breakpoint($screen-xl) {
            width: auto;
        }
    }
}

.collaboratively-container {
    min-height: 480px;
    position: relative;
    margin-bottom: 100px;
    @extend %with-large-background;

    @include breakpoint($screen-md) {
        background: url(../../assets/img/payors/payors-bg-1.svg) no-repeat top;
        background-size: 102%;
    }

    .text {
      @include breakpoint( 0px $screen-md) {
        text-align: center;
      }
    }

    .section-text {
        margin-top: 150px;
        position: relative;
        top: 40px;
        flex-basis: 50%;
        padding-left: 10%;

        h3 {
            margin-bottom: 5px;
        }

        p {
            max-width: 535px;
        }
    }

    .section-image {
        width: 100%;
    }
    @extend %responsive-content-section;
}

.evidence-based {
    min-height: 280px;
    position: relative;
    margin-bottom: 100px;
    @extend %with-large-background;
    @include breakpoint($screen-md) {
        background: url(../../assets/img/payors/payors-bg-2.svg) no-repeat center;
        background-size: 102%;
    }

    .text {
      @include breakpoint( 0px $screen-md) {
        text-align: center;
      }
    }

    .section-text {
        position: relative;
        top: 40px;
        padding-left: 10%;

        h3 {
            margin-bottom: 5px;
        }

        p {
            max-width: 535px;
        }
    }

    .section-image {
        width: 100%;
        @include breakpoint($screen-xs $screen-md) {
            margin-right: -15px;
        }
        @extend %with-large-images;
    }

    @extend %mobile-reverse;
}

.team-deployment {
    position: relative;
    padding-top: 25px;
    @extend %with-large-background;
    @include breakpoint($screen-md) {
        padding-top: 100px;
        background: url(../../assets/img/payors/payors-bg-4.svg) no-repeat center;
        background-size: 102%;
    }

    .section-image {
        width: 100%;
        @include breakpoint($screen-xs $screen-md) {
            margin-right: -15px;
        }
        @extend %with-large-images;
    }

    .text {
      @include breakpoint( 0px $screen-md) {
        text-align: center;
      }
    }

    .section-text {
        flex-basis: 50%;
        padding-left: 10%;

        h3 {
            margin-bottom: 5px;
        }

        p {
            max-width: 535px;
        }
    }
    .section-image {
        width: 100%;
        @extend %with-large-images;
    }

    @extend %mobile-reverse;
}

.digital-experience {
    min-height: 480px;
    position: relative;
    @extend %with-large-background;

    @include breakpoint($screen-md) {
        background: url(../../assets/img/payors/payors-bg-3.svg) no-repeat top;
        background-size: 102%;
    }

    .text {
      @include breakpoint( 0px $screen-md) {
        text-align: center;
      }
    }

    .section-text {
        margin-top: 150px;
        position: relative;
        top: 40px;
        flex-basis: 50%;
        padding-left: 10%;

        h3 {
            margin-bottom: 5px;
        }

        p {
            max-width: 535px;
        }
    }

    .section-image {
        width: 100%;
    }
}

.outcomes-pearl {
    background: $pearl-lusta;
    padding: 50px 0 0;
    @include breakpoint(($screen-md + 1)) {
        padding: 75px 0;
    }

    h2 {
        margin-bottom: 50px;

        @include breakpoint($screen-md) {
            margin-bottom: 0.5rem;
        }
    }

    h3 {
        line-height: 50px;
        margin-top: 20px;

        @include breakpoint($screen-md) {
            margin-top: 0;
        }
    }

    p {
        font-family: $font-futura;
        line-height: 30px;
        font-size: 20px;

        @include breakpoint($screen-md) {
            line-height: 40px;
            font-size: 25px;
        }
    }

    @include breakpoint($screen-xs ($screen-md - 1)) {
        padding: 30px 0 0 !important;
        text-align: center;
    }

    .right-side {
        padding: 0 30px;
        @include breakpoint($screen-md) {
            padding: 0 15px;
        }
    }
}
