// Typography
$font-futura: 'futura-pt', sans-serif;
$font-futura-demi: 'futura-pt-demi', sans-serif;
$font-futura-book: 'Futura PT Book', sans-serif;
$font-futura-light: 'futura-pt-light', sans-serif;
$font-futura-condensed: 'futura-pt-condensed', sans-serif;
$font-oldstyle: 'poynter-oldstyle-display', sans-serif;
$font-oldstyle-condensed: 'poynter-oldstyle-display-con', sans-serif;
$font-oldstyle-narrow: 'poynter-oldstyle-display-nar', sans-serif;
$font-size-base: 20px; // 20px

// Colors
$white: #FFFFFF;

$tradewind: #50AFA8;
$neptune: #72BFB9;
$swans-down: #D0EAE7;
$aqua-squeeze: #ECF7F5;
$spring-wood: #FAF7F5;

$yellow-orange: #FFAA39;
$marzipan: #F8E29A;
$pearl-lusta: #FCF6E0;

$outer-space: #2B323B;
$limed-spruce: #37404A;
$regent-gray: #7B8B9D;
$ghost: #C4CBD4;
$athens-gray: #F0F2F4;
$black:  #000 !default;

$fade: #D3D8DE;
$fade-sec: #CBD1D9;
// Data Viz Colors
$danube: #72A1D9;
$tapestry: #AF5098;
$viola: #C785B7;
$navbar-light-color: rgba($black, .5) !default;

// Validation
$alizarin-crimson: #E11E1E;
$pistachio: #89C201;
$indigo: #3A7CCA;

// Screen Size
$screen-xs: 0;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 1200px;
$screen-xl: 1450px;
$screen-xxl: 1700px;

$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>")