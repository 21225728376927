@import "~breakpoint-sass/stylesheets/_breakpoint";
@import "src/scss/utils/variables";
.header-nav {
  margin: 0 !important;
  position: fixed;
  top: 0;
  background: $spring-wood;
  z-index: 9999;

  @include breakpoint($screen-md) {
    background: $white;
    position: relative;
    top: initial;
  }

  .logo-container {
    padding-left: 10px;
    padding-top: 15px;
    padding-bottom: 15px;

    @include breakpoint($screen-lg) {
      padding-left: 60px;
    }

    .logo {
      height: 60px;
    }
  }

  .menu-container {
    display: flex;
    justify-content: flex-end;

  }

  .menu {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;

    .item {
      padding: 25px;

      &.active {
        border-bottom: 3px solid #72BFB9;

        a {
          font-size: 18px;
          font-weight: 600;
        }
      }

      a {
        text-decoration: none;
        color: #000;
        font-weight: 500;
      }
    }
  }

  @media (max-width: 768px) {
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .logo-container-mobile {
      padding: 10px;

      .logo-mobile{
        height: 40px;
      }
    }

    .menu {
      display: flex;
      justify-content: flex-end;
      padding-right: 0;

      .mobile-menu-label {
        font-family: $font-futura-demi;
        font-size: 20px;
        color: $limed-spruce;
        letter-spacing: 0;
        text-align: center;
        line-height: 30px;
        padding: 15px 20px;

        .navbar-toggler-icon {
          background-image: $navbar-light-toggler-icon-bg;
        }
      }
    }

    .menu-mobile {
      border-top: 1px solid #D3D8DE;
      border-bottom: 1px solid #D3D8DE;

      &.hide {
        visibility: hidden;
        height: 0;
      }

      &.show {
        visibility: visible;
        transition: height 0.5s ease;
      }

      .item {

        &.active {
          border-bottom: 3px solid #72BFB9;

          a {
            font-weight: 600;
          }
        }

        a {
          text-decoration: none;
          color: #000;
          font-weight: 500;
          display: block;
          width: 100%;
        }
      }
    }
  }

  &.spring-wood {
    background: $spring-wood;
  }
}
