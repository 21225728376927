@import "../../styles/variables";

.card_main {
  position: relative;
}
.card {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .left {
    flex: 1;
    @media (max-width: 767px) {
      width: 100%;
    }
    .img_box {
      height: 500px;
      margin-right: 100px;
      .img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        display: flex;
        align-items: center;
        @media (max-width: 1099px) {
          max-width: 46%;
          img {
            max-width: 100%;
          }
        }
        @media (max-width: 767px) {
          position: static;
          max-width: 75%;
          margin-left: -20px;
        }
        @media (max-width: 479px) {
          max-width: 90%;
        }
        img {
          max-height: 100%;
        }
      }
      @media (min-width: 1500px) {
        height: 550px;
      }
      @media (min-width: 1600px) {
        height: 650px;
      }
      @media (min-width: 1900px) {
        height: 700px;
      }
      @media (max-width: 1199px) {
        height: 400px;
        margin-right: 50px;
        .img {
          max-height: 400px;
          img {
            max-height: 400px;
          }
        }
      }
      @media (max-width: 1099px) {
        height: auto;
      }
    }
  }
  .right {
    flex: 1;
    display: flex;
    align-items: center;
    .info {
      margin-top: 20px;
    }
    @media (min-width: 768px) {
      min-height: 545px;
    }
  }
}
.card_full {
  &.theme2 {
    .left {
      @media (min-width: 768px) {
        order: 3;
      }
      .img_box {
        margin-right: 0;
        margin-left: 60px;
        .img {
          left: auto;
          right: 0;
        }
        @media (max-width: 767px) {
          margin-left: 0;
          margin-right: -20px;
          .img {
            margin-left: auto;
            position: relative;
            margin-right: 0;
          }
        }
        @media (max-width: 479px) {
          .img {
            max-width: 75%;
          }
        }
      }
    }
    .right {
      .info {
        max-width: 500px;
      }
    }
  }
  &.theme3 {
  }
  &.theme4 {
    .left {
      @media (min-width: 768px) {
        order: 3;
      }
      .img_box {
        margin-right: 0;
        margin-left: 60px;
        .img {
          left: auto;
          right: 0;
        }
        @media (max-width: 767px) {
          margin-left: 0;
          margin-right: -20px;
          .img {
            margin-left: auto;
            position: relative;
            margin-right: 0;
          }
        }
        @media (max-width: 479px) {
          .img {
            max-width: 75%;
          }
        }

        @media (max-width: 1199px) {
          .img {
            img {
              max-height: 400px;
              margin-left: auto;
            }
          }
        }
      }
    }
    .right {
      .info {
        max-width: 500px;
      }
    }
  }
}
