.form-group {
    position: relative;
    margin-bottom: 30px;
}

.form-control {
    border: 1px solid $fade;
    padding: 15px 20px;
    height: 60px;
    color: $limed-spruce;
    font-family: $font-futura;
    &:focus {
        box-shadow: none;
        border: 1px solid $indigo;
    }

    &::placeholder,
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder {
        color: $fade;
        font-size: 20px;
        font-family: $font-futura;
    }

    &.is-invalid {
        border-color: $alizarin-crimson;
        background-image: url(../../assets/img/x-icon.svg);
        background-size: 15px 15px;
    }
}

.invalid-feedback {
    font-size: 15px;
    color: $alizarin-crimson;
    line-height: 25px;
}

select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 15px 60px 15px 20px;
    background: url(../../assets/img/down-caret.svg) no-repeat right;
    background-position: right 20px center;
    background-color: $white;
}

/*label {
    margin: 20px 0;
    position: relative;
    display: inline-block;
}

span {
    color: $fade-sec;
    font-family: $font-futura;
    font-size: 20px;
    padding: 0 .75rem;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0.5;
}

input:focus + span, input:not(:placeholder-shown) + span {
    opacity: 1;
    transform: scale(0.75) translateY(-100%) translateX(-30px);
}

// For IE Browsers
input:focus + span, input:not(:-ms-input-placeholder) + span {
    opacity: 1;
    transform: scale(0.75) translateY(-100%) translateX(-30px);
}*/