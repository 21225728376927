body, #root {
    overflow-x: hidden;
}
#root {
    padding-top: 65px;
    @include breakpoint($screen-md) {
        padding-top: 0;
    }
}
.container-fluid-avenu {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.page-hero {
    background: $spring-wood;
    padding: 100px 410px 100px 210px;

    @include breakpoint($screen-xs $screen-md) {
        text-align: center;
        padding: 0;
    }

    h2 {
        line-height: 65px;
        @include breakpoint($screen-xs $screen-md) {
            line-height: 40px;
        }
    }
}

.hero-section {
    height: 75vh;
}

.page-content {
    margin: 90px 0;
    font-family: $font-futura;

    @include breakpoint($screen-xs $screen-md) {
        margin: 30px 0;
    }

    h3 {
        max-width: 50%;
    }

    form {
        @include breakpoint($screen-xs $screen-md) {
            margin-bottom: 30px;
        }
    }
}

.page-title {
    margin: 110px auto 50px;
    max-width: 70%;
    @include breakpoint($screen-xl) {
        max-width: 40%;
    }

    @include breakpoint($screen-xs $screen-md) {
        margin: 20px auto;
    }
}

.source-list {
    font-family: $font-futura;
    font-size: 15px;
    color: $regent-gray;
    letter-spacing: 0;
    line-height: 25px;
    @include breakpoint($screen-xs $screen-md) {
        text-align: left;
        margin-bottom: 0;
    }
}

.copy-text {
    font-family: $font-futura;
    letter-spacing: 0;
    line-height: 25px;
}