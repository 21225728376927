@import "~breakpoint-sass/stylesheets/_breakpoint";
@import "src/scss/utils/variables";

.footer {
  //position: absolute;
  width: 100%;
  bottom: 0;
  margin: 0 !important;
  background-color: $limed-spruce;
  color: #D8D8D8;

  .mt-30 {
    margin-top: 30px;
  }

  .title {
    text-transform: uppercase;

    h4 {
      line-height: 1.9;
      font-family: $font-futura-demi;
      font-size: 18px;
      color: #FFFFFF;
      letter-spacing: 1px;
    }
  }

  .container-links {
    display:flex;
    flex-direction: column;
    font-family: $font-futura-demi;
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 1.8;

    a {
      color: $white;
      text-decoration: none;
    }
  }

  .logo-container {
    display: flex;
    justify-content: center;

    .logo-text {
      height: 45px;
      margin-left: 10px;
      margin-top: 10px;
    }
  }

  .social-container {
    display: flex;

    .logo-linkedin {
      height: 25px;
    }
  }
}

.footer-copyright {
  background-color: #2C323A;
  color: #D8D8D8;
  font-family: "futura-pt", sans-serif;
  font-size: 16px;
  padding: 20px 0;

  .linkedin-mobile-logo {
    margin-bottom: 20px;
    @include breakpoint($screen-md) {
      display: none;
    }
  }
}