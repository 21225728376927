@import "../../styles/variables";

.card {
  .img_box {
    padding: 30px 0;
    position: relative;
    @media (max-width: 991px) {
      padding: 0;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 50px;
      bottom: 0;
      left: -5000px;
      background-color: $color-yellow-light2;
      z-index: -1;
      border-radius: 500px;
      @media (max-width: 991px) {
        bottom: -30px;
        top: 50px;
        left: 50px;
        right: -500px;
      }
    }
  }
  .img {
    position: relative;
    padding-bottom: 67%;
    border-radius: 10px;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .link {
    font-size: 1.39rem;
    font-family: $font-medium;
    color: $color-green;
    padding: 10px 0;
    display: inline-block;
    &:after {
      content: "";
      display: block;
      height: 2px;
      background-color: $color-green;
      margin-top: 5px;
      transition: 0.3s;
      width: 0;
      opacity: 0;
    }
    &:hover {
      &:after {
        width: 100%;
        opacity: 1;
      }
    }
    @media (max-width: 1299px) {
      font-size: 1.25rem;
    }
    .link_text {
      text-decoration: none !important;
      font-size: inherit !important;
      @media (max-width: 1199px) {
        &[data-title] {
          font-size: 0 !important;
          &:before {
            content: attr(data-title);
            font-size: 1.39rem !important;
            vertical-align: middle;
          }
          .arrow_right {
            margin: 0;
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }
  &.right {
    .img_box {
      &:before {
        right: -5000px;
        left: 50px;
        background-color: $color-green-light2;

        @media (max-width: 991px) {
          bottom: -30px;
          top: 50px;
          right: 50px;
          left: -500px;
        }
      }
    }
  }
}
.info {
  padding-top: 15px;
  @media (min-width: 992px) {
    .heading_box {
      min-height: 105px;
    }
    div[data-content] {
      min-height: 170px;
    }
  }
  @media (min-width: 1600px) {
    .heading_box {
      min-height: 125px;
    }
  }
  @media (min-width: 1900px) {
    .heading_box {
      min-height: 135px;
    }
  }
  @media (max-width: 991px) {
    padding-top: 50px;
  }
}
.arrow_right {
  display: inline-block;
  width: 20px;
  height: 2px;
  background-color: $color-green;
  vertical-align: middle;
  position: relative;
  margin: 10px 3px;
  &:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid $color-green;
    border-right: 2px solid $color-green;
    position: absolute;
    top: -4px;
    right: 0;
    transform: rotate(45deg);
  }
}
