@import "../../styles/variables";

.exp_card {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    .left {
      order: 3;
      width: 100%;
    }
  }
  .left {
    flex: 1;
  }
  .right {
    flex: 1;
  }
  .count {
    font-size: 10rem;
    font-family: $font-bold;
    color: $color-green;
    line-height: 1;
    @media (max-width: 1199px) {
      font-size: 8rem;
    }
    @media (max-width: 991px) {
      display: none;
    }
  }
  .count_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (min-width: 1200px) {
      margin-right: 50px;
    }
    .icon {
      width: 140px;
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: end;
      margin-right: 30px;
      @media (max-width: 1299px) {
        width: 100px;
        height: 100px;
        margin-right: 20px;
      }
      @media (max-width: 991px) {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: transparent
          linear-gradient(
            104deg,
            #f7a63b 9%,
            #f3d792 46%,
            #bde1e0 83%,
            #8ac2cb 100%
          )
          0% 0% no-repeat padding-box;
        padding: 20px;
        justify-content: center;
        img {
          filter: brightness(0) invert(1);
        }
      }
      @media (max-width: 991px) {
        margin-right: 0;
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .content_box {
    max-width: 550px;
    padding: 30px 50px 30px 0;
    position: relative;
    @media (min-width: 1500px) {
      max-width: 650px;
      padding-top: 50px;
      padding-bottom: 50px;
      padding-right: 100px;
    }
    @media (min-width: 1900px) {
      max-width: 750px;
    }
    @media (max-width: 991px) {
      padding-right: 0;
      max-width: 450px;
      margin: 0 auto;
      div[data-heading] {
        text-align: center;
      }
    }
    @media (max-width: 991px) {
      div[data-heading] {
        &:before {
          content: attr(data-title);
        }
      }
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: -5000px;
      bottom: 0;
      background-color: $color-orange-light;
      border-radius: 600px;
      z-index: -1;
      @media (max-width: 1299px) {
        right: 30px;
      }
      @media (max-width: 1199px) {
        right: 0px;
      }
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
  &.align_right {
    @media (min-width: 992px) {
      .left {
        order: 3;
        .content_box {
          padding-right: 0;
          padding-left: 120px;
          @media (max-width: 1199px) {
            padding-left: 150px;
          }
          margin-left: auto;
          &:before {
            left: 0;
            right: -5000px;
            @media (max-width: 1299px) {
              left: 30px;
            }
          }
        }
      }
      .count_box {
        justify-content: flex-start;
      }
    }
  }
  &.theme1 {
    .content_box {
      &:before {
        background-color: $color-orange-light;
      }
    }
  }
  &.theme2 {
    .content_box {
      &:before {
        background-color: $color-yellow-light;
      }
    }
  }
  &.theme3 {
    .content_box {
      &:before {
        background-color: $color-green-light;
      }
    }
  }
}
