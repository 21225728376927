@import "~breakpoint-sass/stylesheets/_breakpoint";
@import "src/scss/utils/variables";
@import "src/scss/utils/mixins";

.revenue-center {
    background: url(../../assets/img/providers/providers-bg-1.svg) no-repeat top;
    background-size: 102%;
    min-height: 600px;
    position: relative;
    margin-bottom: 100px;
    @extend %with-large-background;
    @extend %with-small-no-background;

    @include breakpoint($screen-xl) {
        min-height: 600px;
    }

    .section-text {
        margin-top: 150px;
        position: relative;
        top: 40px;
        padding-left: 5%;

        p {
          max-width: 535px;
        }
    }

    .section-image {
        @extend %with-large-images;
    }
    @extend %responsive-content-section;

}

.ondemand-care {
    background: url(../../assets/img/providers/providers-bg-2.svg) no-repeat top;
    background-size: 102%;
    min-height: 600px;
    position: relative;
    margin-bottom: 100px;
    @extend %with-large-background;
    @extend %with-small-no-background;

    .section-image {
      text-align: center;
        width: 100%;
    }

    .section-text {
      margin-top: 100px;
      position: relative;
      top: -20px;
      flex-basis: 45%;
      padding-left: 10%;


      p {
        max-width: 535px;
      }
    }

    .text {
      @include breakpoint( 0px $screen-md) {
        text-align: center;
      }
    }
    @extend %mobile-reverse;
    @extend %responsive-content-section;
}

.digital-experience-providers {
    background: url(../../assets/img/providers/providers-bg-3.svg) no-repeat center;
    background-size: 102%;
    min-height: 420px;
    position: relative;
    margin-bottom: 120px;
    @extend %with-large-background;
    @extend %with-small-no-background;

    @include breakpoint($screen-xl) {
        margin-bottom: 60px;
        min-height: 500px;
    }

    .section-image {
        img {
            width: 100%;
            @include breakpoint($screen-xl) {
                width: auto;
                height: 494px;
            }
        }

        @extend %with-large-images;
    }

    .text {
      @include breakpoint( 0px $screen-md) {
        text-align: center;
      }
    }


    .section-text {
      margin-top: 100px;
      position: relative;
      top: -20px;

      p {
        max-width: 535px;
      }
    }

    @extend %responsive-content-section;
}

.partner-program {
  .partner-program-img {
      width: 65%;

      @include breakpoint($screen-md) {
          width: 80%;
          margin-left: -30px;
      }
      @include breakpoint($screen-xl) {
          width: auto;
          margin-left: 0;
      }
  }
}

.dots-image {
  width: 100%;
}

.single-image {
    display: none;
    @include breakpoint($screen-sm) {
        display: block;
    }
}

.many-images {
    display: block;
    @include breakpoint($screen-sm) {
        display: none;
    }
    > div {
        width: 80%;
        margin: 0 auto 20px;
        img {
            margin: 0 auto;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    h3 {
        line-height: 35px;
        margin: 25px auto 0;
    }
}

.outcomes-aqua {
    background: $aqua-squeeze;
    padding: 100px 0 0;

    h2 {
        margin-bottom: 50px;

        @include breakpoint($screen-md) {
            margin-bottom: 0.5rem;
        }
    }

    h3 {
        margin-top: 20px;

        @include breakpoint($screen-md) {
            margin-top: 0;
        }

        line-height: 50px;
    }
    p {
        font-family: $font-futura;
        line-height: 30px;
        font-size: 20px;

        @include breakpoint($screen-md) {
            line-height: 40px;
            font-size: 25px;
        }
    }

    .right-side {
        padding: 0 30px;
        @include breakpoint($screen-md) {
            padding: 0 15px;
        }
    }

    @extend %responsive-outcomes;
}

.break-down-barriers {
    padding: 0;
    text-align: center;
    @include breakpoint($screen-xs $screen-md) {
        padding: 30px 0;
    }
    h2 {
        margin-bottom: 100px;
        line-height: 65px;
        @include breakpoint($screen-xs $screen-md) {
            margin-bottom: 30px;
            line-height: 45px;
        }
    }
}
