// NO bootstrap outline
%no-outline {
	&:focus, &:active {
		outline: none !important;
	}
}

//Position and display helpers
.relative {
	position: relative;
}

.inline-block {
	display: inline-block;
}
// Paddings
.no-padding {
	padding: 0 !important;
}

.p-right {
	&-0 {padding-right: 0!important;}
	&-5 {padding-right: 5px!important;}
	&-10 {padding-right: 10px!important;}
	&-20 {padding-right: 20px!important;}
	&-30 {padding-right: 30px!important;}
	&-40 {padding-right: 40px!important;}
}

.p-left {
	&-0 {padding-left: 0!important;}
	&-5 {padding-left: 5px!important;}
	&-10 {padding-left: 10px!important;}
	&-20 {padding-left: 20px!important;}
	&-25 {padding-left: 25px!important;}
	&-30 {padding-left: 30px!important;}
	&-40 {padding-left: 40px!important;}
}

.p-top {
	&-0 { padding-top: 0!important;}
	&-5 { padding-top: 5px!important;}
	&-10 { padding-top: 10px!important;}
	&-20 { padding-top: 20px!important;}
	&-25 { padding-top: 25px!important;}
	&-30 { padding-top: 30px!important;}
	&-40 { padding-top: 40px!important;}
	&-50 { padding-top: 50px!important;}
}

.p-bottom {
	&-0 {padding-bottom: 0!important;}
	&-5 {padding-bottom: 5px!important;}
	&-10 {padding-bottom: 10px!important;}
	&-20 {padding-bottom: 20px!important;}
	&-30 {padding-bottom: 30px!important;}
	&-40 {padding-bottom: 40px!important;}
}
// Margins
 
.no-margin {
	margin: 0 !important;
}

.m-bottom {
	&-0 { margin-bottom: 0 !important; }
	&-5 { margin-bottom: 5px !important; }
	&-10 { margin-bottom: 10px !important; }
	&-15 { margin-bottom: 15px !important; }
	&-20 { margin-bottom: 20px !important; }
	&-25 { margin-bottom: 25px !important; }
	&-30 { margin-bottom: 30px !important; }
	&-35 { margin-bottom: 35px !important; }
	&-40 { margin-bottom: 40px !important; }
	&-50 { margin-bottom: 50px !important; }
	&-60 { margin-bottom: 60px !important; }
	&-70 { margin-bottom: 70px !important; }
}

.m-top {
	&-0 { margin-top: 0 !important; }
	&-5 { margin-top: 5px !important; }
	&-10 { margin-top: 10px !important; }
	&-15 { margin-top: 15px !important; }
	&-20 { margin-top: 20px !important; }
	&-25 { margin-top: 25px !important;}
	&-30 { margin-top: 30px !important; }
	&-40 { margin-top: 40px !important; }
	&-50 { margin-top: 50px !important; }
	&-60 { margin-top: 60px !important; }
	&-70 { margin-top: 70px !important; }
	&-80 { margin-top: 80px !important; }
	&-90 { margin-top: 90px !important; }
}

.m-right {
	&-0 { margin-right: 0!important; }
	&-5 { margin-right: 5px!important; }
	&-10 { margin-right: 10px!important; }
	&-15 { margin-right: 15px!important; }
	&-20 { margin-right: 20px!important; }
	&-25 { margin-right: 25px!important; }
}

.m-left {
	&-0 {margin-left: 0!important;}
	&-5 {margin-left: 5px!important;}
	&-10 {margin-left: 10px!important;}
	&-20 {margin-left: 20px!important;}
	&-25 {margin-left: 25px!important;}
	&-30 {margin-left: 30px!important;}
}

.align-left {
    text-align: left !important;
}
.align-center {
    text-align: center !important;
}
.align-right {
    text-align: right !important;
}
p {
    &.note {
        margin-bottom: 0;   
    }
}

[hidden] {
	display: none !important;
}

.bg-white {
	background: $white;
}

.col-centered{
	float: none;
	margin: 0 auto;
}

.vcenter {
	display: inline-block;
	vertical-align: middle !important;
	float: none;
}

.vtop {
	display: inline-block;
	vertical-align: top;
	float: none;
}

.full-width {
	width: 100% !important;
}

.clearfix {
	clear: both!important;
}
.v-align-middle {
	vertical-align: middle;
}
.v-middle {
	display: inline-block;
	vertical-align: middle;
}
.v-top {
	display: inline-block;
	vertical-align: top;
}

ul.inside {
	list-style: disc;
	padding-left: 1em;
}

.float-left {
	float: left !important;
}

.float-right {
	float: right !important;
}

.disabled {
	/* IE 8 */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	/* IE 5-7 */
	filter: alpha(opacity=50);
	/* Netscape */
	-moz-opacity: 0.5;
	/* Safari 1.x */
	-khtml-opacity: 0.5;
	/* Good browsers */
	opacity: 0.5;
	pointer-events: none;
}

.italic { font-style: italic!important; }
.overflow-hidden { overflow: hidden!important; }

.transform-uppercase {
	text-transform: uppercase;
}

hr {
	border-width: 1px;
}

.vertical-centered {
	top: 50%;
	transform: translateY(-50%);
}
.vertical-middle {
	display: inline-block!important;
	vertical-align: middle!important;
}

.no-padding-col {
	padding-left: 0;
	padding-right: 0;
}

.pt-80 {
	padding-top: 80px;
}

.pl-10 {
	padding-left: 10%;
}

.p-left-35 {
	padding-left: 35px;
}

.plr {
	&-5 {
		padding-left: 5% !important;
		padding-right: 5% !important;
	}
	&-10 {
		padding-left: 10% !important;
		padding-right: 10% !important;
	}
}

.ptb-10 {
	padding-top: 10px;
	padding-bottom: 10px;
}

.pb-80 {
	padding-bottom: 80px;
}

a.large.no-underline {
	max-width: 70%;
	display: inline-block;
	line-height: 30px;
	img {
		margin-left: 0;
		vertical-align: baseline;

		@include breakpoint($screen-md) {
			margin-left: 10px;
		}
	}
}