@mixin button($type: 'primary') {
  line-height: 3;
  font-size: 20px;
  border-radius: 2.5rem;
  box-sizing: border-box;
  border: none;
  $bg: $neptune;
  $color: $white;
  $border: none;
  $the-font: $font-futura-demi;
  $padding: 0 30px;

  @if ($type == 'secondary') {
    $bg: $white;
    $color: $neptune;
    $border: inset 0 0 0 1px $neptune;
  }

  padding: $padding;
  background-color: $bg;
  color: $color;
  box-shadow: $border;
  font-family: $the-font;
  letter-spacing: 0.04px;



  &:visited {
    color: $color;
  }
  &:hover {
    background-color: $neptune;
    color: $white;
    box-shadow: inset 0 0 0 2px $neptune;
  }

  &:disabled {
    background-color: #6a6a6a;
    box-shadow: $border;
  }

  &:disabled:hover {
    background-color: $bg;
    color: $color;
    border: $border;
    box-shadow: $border;
  }

  &:focus {
    outline: none;
    background-color: $neptune;
    color: $white;
  }

  &:active,
  &:active:focus:hover {
    border: 0 inset $neptune;
    background-color: $neptune;
    box-shadow: none;
  }

  &.btn-wide {
    padding: 0 30px;
    @include breakpoint($screen-xl) {
      padding: 0 50px;
    }
  }
}

button {
  &,
  &.btn,
  &.btn-default,
  &.btn.btn-default {
    &:focus,
    &:active {
      outline: none !important;
    }
  }
}

.btn-primary {
  @include button();
}

/*.btn-secondary {
  @include button('secondary');
  &:focus {
    background-color: $white;
    color: $night;
  }
  &:focus:active {
    box-shadow: inset 0 0 0 2px $night;
  }
}*/