@import "./variables";

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  font-size: 16px !important;
  scroll-behavior: smooth;

  // @media (min-width: 1270px) {
  //   font-size: 16px !important;
  // }
  // @media (min-width: 1500px) {
  //   font-size: 17px !important;
  // }
  // @media (min-width: 1600px) {
  //   font-size: 18px !important;
  // }
  // @media (min-width: 1900px) {
  //   font-size: 20px !important;
  // }

  // @media (max-width: 991px) {
  //   font-size: 16px !important;
  // }
}
*,
:after,
:before {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: 0 !important;
}
* {
  -webkit-tap-highlight-color: transparent;
}
body {
  font-weight: normal;
  line-height: 1.25;
  color: $color-dark;
  font-family: $font-book;
  font-size: 0.875rem;
  overflow-x: hidden;
  letter-spacing: 0.5px;
  min-width: 320px;
  min-height: 100vh;
  background: $color-white;
  position: relative;
}
body div#root {
  position: relative;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}
p {
  line-height: 1.5;
  margin-bottom: 1rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font: inherit;
}
button,
input,
textarea,
select {
  font-family: $font-book;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
ul,
ol {
  list-style: none;
}

div[data-container] {
  padding-left: 25px;
  padding-right: 25px;
  margin: 0 auto;
  max-width: 1440px;
  @media (max-width: 1499px) {
    max-width: 1300px;
  }
  @media (max-width: 1399px) {
    max-width: 1240px;
  }
  @media (max-width: 1269px) {
    max-width: 1200px;
  }
  @media (max-width: 1259px) {
    max-width: 1100px;
  }
  @media (max-width: 1199px) {
    max-width: 920px;
  }
  @media (max-width: 991px) {
    max-width: 720px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
}

div[data-heading] {
  font-family: $font-heading;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.125;
  margin-bottom: 25px;
  @media (max-width: 768px) {
    font-size: 25px;
  }
  &[data-heading="h1"] {
    font-size: 4.6875rem;
    margin-bottom: 30px;
    @media (max-width: 767px) {
      font-size: 3.6125rem;
      margin-bottom: 20px;
    }
    @media (max-width: 575px) {
      font-size: 2.75rem;
    }
    @media (max-width: 399px) {
      font-size: 2.25rem;
    }
  }
  &[data-heading="h2"] {
    font-size: 50px;
    margin-bottom: 25px;
    @media (max-width: 1199px) {
      font-size: 40px;
    }
    @media (max-width: 768px) {
      font-size: 30px;
    }
  }
}
div[data-content] {
  font-family: $font-book;
  font-size: 25px;
  line-height: 1.5;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
}
ul[data-list] {
  > li {
    list-style: none;
    padding-left: 20px;
    position: relative;
    line-height: 1.25;
    margin-bottom: 15px;
    position: relative;
    @media (max-width: 1199px) {
      margin-bottom: 10px;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-dark;
      left: 0;
      top: 10px;
    }
  }
}
ul[data-list2] {
  > li {
    list-style: none;
    padding-left: 60px;
    position: relative;
    line-height: 1.25;
    margin-bottom: 25px;
    position: relative;
    @media (max-width: 767px) {
      padding-left: 40px;
      padding-top: 2px;
    }
    @media (max-width: 575px) {
      padding-top: 5px;
      margin-bottom: 15px;
      div[data-heading] {
        font-family: $font-book;
        font-size: 1.5rem;
        font-weight: normal;
      }
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: $color-green;
      left: 0;
      top: 5px;
      @media (max-width: 767px) {
        width: 25px;
        height: 25px;
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 8px;
      height: 15px;
      border-right: 2px solid $color-white;
      border-bottom: 2px solid $color-white;
      left: 13px;
      top: 13px;
      transform: rotate(45deg);
      @media (max-width: 767px) {
        width: 6px;
        height: 11px;
        left: 10px;
        top: 11px;
      }
    }
  }
}
