@import "../../styles/variables";

.section {
  padding: 75px 0;
  overflow: hidden;
  @media (max-width: 767px) {
    padding: 30px 0;
  }
  &.section_program {
    padding-bottom: 0;
    @media (max-width: 991px) {
      padding-bottom: 60px;
    }
    @media (max-width: 767px) {
      padding-top: 0px;
      .heading_box {
        background-color: $color-light;
        text-align: left;
        padding-bottom: 50px;
        div[data-heading] {
          font-size: 1.5rem;
          font-family: $font-demi;
          font-weight: normal;
        }
      }
    }
  }
  &.section_1 {
    @media (max-width: 991px) {
      background-color: $color-light;
    }
  }
}
.heading_box {
  max-width: 830px;
  margin: 0 auto;
  margin-bottom: 60px;
  text-align: center;
  @media (max-width: 767px) {
    margin-bottom: 50px;
  }
}
.program_full {
  position: relative;
  padding-bottom: 140px;
  @media (max-width: 991px) {
    padding-bottom: 0;
  }
  .line_design1 {
    position: absolute;
    top: 220px;
    left: 0;
    width: 100%;
    background-color: $color-green-light;
    height: 40px;
    @media (max-width: 991px) {
      display: none;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      height: 40px;
      background-color: $color-light;
      left: -30px;
      width: calc(50% + 200px);
      top: 170px;
      border-radius: 20px;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 40px;
      background-color: $color-light;
      left: calc(50% + 110px);
      width: 200px;
      top: 110px;
      border-radius: 20px;
      transform: rotate(-45deg);
    }
  }
  .line_design2 {
    position: absolute;
    bottom: 80px;
    left: -60px;
    width: calc(50% + 300px);
    background-color: $color-green-light;
    height: 40px;
    border-radius: 20px;
    @media (max-width: 991px) {
      display: none;
    }
    &:before {
      content: "";
      position: absolute;
      background-color: $color-green-light;
      width: 150px;
      height: 40px;
      border-radius: 20px;
      transform: rotate(45deg);
      right: -98px;
      top: 40px;
    }
    &:after {
      content: "";
      position: absolute;
      background-color: $color-green-light;
      height: 40px;
      border-radius: 20px;
      left: calc(100% + 45px);
      right: -5000px;
      top: 80px;
    }
  }
}
.program_box {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  @media (max-width: 991px) {
    display: block;
  }
  .program_item {
    max-width: 520px;

    @media (max-width: 1299px) {
      max-width: 480px;
    }
    @media (max-width: 1199px) {
      max-width: 420px;
    }
    @media (max-width: 991px) {
      max-width: 100%;
      + .program_item {
        margin-top: 60px;
      }
    }
  }
}
.section_content {
  background-color: $color-light;
  .about_text {
    max-width: 1170px;
    margin: 0 auto;
    font-size: 1.0625rem;
    color: $color-dark2;
  }
}
.exp_box {
}
.exp_item {
  position: relative;
  [data-list] {
    text-transform: capitalize;
  }
  .line {
    position: absolute;
    left: calc(50% + 40px);
    top: 20px;
    width: 40px;
    bottom: 100px;
    background-color: $color-yellow-light3;
    border-radius: 20px;
    @media (max-width: 991px) {
      display: none;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: calc(100% - 50px);
      right: 51px;
      width: 40px;
      height: 240px;
      background-color: $color-yellow-light3;
      border-radius: 20px;
      transform: rotate(30deg);
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: -20px;
      width: 80px;
      height: 80px;
      background-color: $color-orange;
      border-radius: 50%;
    }
  }
  @media (min-width: 992px) {
    &:nth-child(even) {
      .line {
        left: calc(50% - 62px);
        top: 20px;
        &:before {
          transform: rotate(-30deg);
          right: -50px;
        }
      }
    }
    &:last-child {
      .line {
        bottom: 0;
        &:before {
          display: none;
        }
        &:after {
          top: 35%;
        }
      }
    }
    &:nth-child(2) {
      .line {
        &:after {
          background-color: $color-yellow-light2;
        }
      }
    }
    &:nth-child(3) {
      .line {
        &:after {
          background-color: $color-green;
        }
      }
    }
  }
}
