@import "~breakpoint-sass/stylesheets/_breakpoint";
@import "src/scss/utils/variables";
@import "src/scss/utils/mixins";

.spring-wood-line-bg-mission {
  background: url(../../assets/img/about/about-bg-1.svg) no-repeat bottom;
  background-size: 102%;
  position: relative;
  margin-bottom: 100px;
  @extend %with-large-background;
  @extend %with-small-no-background;

  @include breakpoint( 0px $screen-md) {
    margin-bottom: 40px;
  }

  .section-image {
    @extend %with-large-images;
  }

  .section-text {
    @include breakpoint( 0px $screen-md) {
      text-align: center;
    }
  }
}

.believe-in-human {
  background: url(../../assets/img/about/about-bg-2.svg) no-repeat;
  background-size: 102%;
  background-position-y: 70px;
  @extend %with-large-background;
  @extend %with-small-no-background;

  .section-image {
    width: 100%;
  }

  .section-text {
    @include breakpoint( 0px $screen-md) {
      text-align: center;
    }
  }

  @extend %mobile-reverse;
}

.technologies-extend-capabilities {
  background: url(../../assets/img/about/about-bg-3.svg) no-repeat;
  background-position-y: 0;
  background-size: 102%;
  padding-top: 150px;
  margin-bottom: 100px;

  @extend %with-small-no-background;
  @include breakpoint( 0px $screen-md) {
    padding-top: 50px;
    margin-bottom: 40px;
  }

  @extend %with-large-background;

  .section-image {
    @extend %with-large-images;
  }

  .section-text {
    @include breakpoint( 0px $screen-md) {
      text-align: center;
    }
  }
}


.our-team {
  img {
    max-width: 200px;
  }

  h3 {
    color: #2B323B;
    font-size: 24px;
    line-height: 35px !important;
    margin: 0 auto 20px auto ;
    max-width: 85%;
  }

  .body-copy-large {
    font-size: 24px;
    line-height: 35px !important;
    max-width: 85%;
    margin: 0 auto;
  }

  .no-underline:hover,
  .no-underline:focus,
  .no-underline:visited,
  {
      text-decoration: none;
  }
}
