@import "~breakpoint-sass/stylesheets/_breakpoint";
@import "src/scss/utils/variables";
@import "./fonts";

// Global
html,
body {
  font-family: $font-futura;
  font-size: $font-size-base;
  color: $outer-space;
  line-height: 1;
}

// Fine print
.fp {
  color: $regent-gray;
  font-size: 15px;
  font-weight: 300;
  font-family: $font-futura;
  line-height: 25px;
}

// Headers
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-family: $font-oldstyle;
  line-height: 50px;
}
h1 {
  font-size: 50px;
  font-weight: bold;
  line-height: 1.25;
  @media (min-width: 1200px) {
    min-height: 90px;
  }
  @include breakpoint($screen-lg) {
    font-size: 75px;
    line-height: 1.25;
  }
}

h2 {
  font-size: 40px;
  font-weight: bold;

  @include breakpoint($screen-lg) {
    font-size: 50px;
    line-height: 65px;
  }
}

h3 {
  font-size: 35px;
  font-weight: bold;
  line-height: 35px;
  margin-top: 1rem;
  @include breakpoint($screen-lg) {
    margin-top: initial;
    line-height: 50px;
  }
}

h4 {
  font-family: $font-futura;
  font-size: 18px;
  font-weight: 500;
}

.subheading {
  font-family: $font-futura-book;
  font-size: 25px;
  color: $limed-spruce;
  line-height: 30px;

  @include breakpoint($screen-lg) {
    line-height: 50px;
    font-size: 35px;
    max-width: 80%;
  }
}
// Links
a,
.link {
  cursor: pointer;
  color: $tradewind;
  text-decoration: underline;
  font-family: $font-futura;
  font-size: 20px;

  &:hover {
    color: $tradewind;
    text-decoration: underline;
  }
  &:focus,
  &:visited {
    text-decoration: underline;
    color: $tradewind;
  }

  &.large {
    font-size: 25px;
  }
}

i {
  font-style: normal;
  &.big {
    font-size: 1.3em;
  }
}

.body-copy-large {
  font-size: 25px;
  font-family: $font-futura;
  color: $limed-spruce;
}

.body-copy-small {
  font-size: 20px;
  font-family: $font-futura;
  color: $limed-spruce;
}

@media (max-width: 768px) {
  h1 {
    font-size: 40px;
    font-weight: bold;
  }

  h2 {
    font-size: 30px;
    font-weight: bold;
  }

  h3 {
    font-size: 25px;
    font-weight: bold;
  }

  h4 {
    font-family: $font-futura;
    font-size: 16px;
    font-weight: 500;
  }

  .body-copy-large {
    font-size: 20px;
    font-family: $font-futura;
    color: $limed-spruce;
    text-align: center;
  }

  .body-copy-small {
    font-size: 16px;
    font-family: $font-futura;
    color: $limed-spruce;
  }

  .subheading {
    font-family: $font-futura;
    font-size: 25px;
    color: $limed-spruce;
  }

  .large {
    font-size: 20px !important;
  }
}
