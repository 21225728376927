.circle {
  display: inline-block;
  height: 80px;
  width: 80px;
  border-radius: 50%;

  &.yellow-orange {
    background: $yellow-orange;
  }
  &.swans-down {
    background: $swans-down;
  }
  &.neptune {
    background: $neptune;
  }
  &.marzipan {
    background: $marzipan;
  }
}