@import "src/scss/utils/variables";

.avenu-address {
  background: $aqua-squeeze;
  padding: 50px 0 70px;
}

.invalid-captcha {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

.alert-success-mail {
  .close {
    padding: 10px 1.25rem !important;
  }
}
